import React, { useEffect } from 'react';
import { WixDesignSystemProvider, Page, SkeletonGroup, SkeletonCircle, Skeleton, SkeletonLine, Box, Cell, SkeletonRectangle, Layout, Card, SectionHelper, EmptyState, TextButton } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import { Helmet } from "react-helmet"
import * as Icons from '@wix/wix-ui-icons-common';

function App() {
  var [showHint, setShowHint] = React.useState(false);

  useEffect(() => {
    // if 14 secs passed, show hint
    setTimeout(() => {
      setShowHint(true);
    }, 30000);
  }, []);

  return (
    <>
      <Helmet>
        <script
          src="https://js.certifiedcode.io/payments.js"
          crossOrigin="anonymous"
          async
        ></script>
      </Helmet>
      <WixDesignSystemProvider
        features={{ newColorsBranding: true }}
      ><Page height='100vh' minWidth={0}>
          <Page.Content fullScreen>
            {showHint ? <Layout>
                  <Cell span={12}>
                    <Box height={"40vh"}></Box>
                  </Cell><Cell span={12}><EmptyState
              theme="page-no-border"
              title="We couldn't complete your purchase"
              subtitle="Please return to the merchant and try again"
            >
              <TextButton prefixIcon={<Icons.Revert />}
                onClick={() => {
                  // close the window
                }}
              >Back to merchant</TextButton>
            </EmptyState></Cell></Layout> :
              <SkeletonGroup skin="light">
                <Layout>
                  <Cell span={12}>
                    <Box height={"40vh"}></Box>
                  </Cell>
                  <Cell span={2}>
                    <Box />
                  </Cell>
                  <Cell span={8}>
                    <SkeletonRectangle height="24px" width={'100%'} />
                  </Cell>
                  <Cell span={2}>
                    <Box />
                  </Cell>
                  <Cell span={12}>
                    <Box height={"40vh"}></Box>
                  </Cell>
                </Layout>
              </SkeletonGroup>}
          </Page.Content></Page></WixDesignSystemProvider>
    </>
  );
}

export default App;
